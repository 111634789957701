/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { errorMsgMap } from './constant';

function isPromise(p) {
  if (typeof p === 'object' && typeof p.then === 'function') {
    return true;
  }

  if (p.constructor.name === 'AsyncFunction') {
    return true;
  }

  return false;
}

function paddingToN(num, n) {
  if (num.toString().length >= n) {
    return num.toString();
  }
  const zeroCount = n - num.toString().length;
  const ret = String('0');
  return `${ret.repeat(zeroCount)}${num}`;
}

// convert timestamp to YYYY/MM
function timestampToMonth(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }`;
}

// convert timestamp to YYYY/MM/DD
function timestampToDate(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }/${
    paddingToN(d.getDate(), 2)
  }`;
}

// convert timestamp to YYYY/MM/DD HH:mm:ss
function timestampToDateTime(ts, dft) {
  let d;
  if (ts instanceof Date) {
    d = new Date(ts);
  } else {
    let timestamp = parseInt(ts, 10);
    if (timestamp.toString().length <= 10) {
      timestamp *= 1000;
    }
    d = new Date(timestamp);
  }

  if (Number.isNaN(d.getTime())) {
    return dft || '';
  }
  return `${
    paddingToN(d.getFullYear(), 4)
  }/${
    paddingToN(d.getMonth() + 1, 2)
  }/${
    paddingToN(d.getDate(), 2)
  } ${
    paddingToN(d.getHours(), 2)
  }:${
    paddingToN(d.getMinutes(), 2)
  }:${
    paddingToN(d.getSeconds(), 2)
  }`;
}

const units = ['B', 'KB', 'MB', 'GB'];

export default {
  install(Vue) {
    Vue.prototype.$showSize = function (value) {
      let unitIdx = 0;
      while (unitIdx < units.length - 1 && value > 1024) {
        unitIdx += 1;
        value /= 1024;
      }
      return `${value.toFixed(2)} ${units[unitIdx]}`;
    };
    Vue.prototype.$timestampToDateTime = timestampToDateTime;
    Vue.prototype.$timestampToDate = timestampToDate;
    Vue.prototype.$timestampToMonth = timestampToMonth;
    Vue.prototype.$isPromise = isPromise;
    Vue.prototype.$execWithLoading = async function (func, errFunc) {
      return this.$execWithLoadingMessage('Loading', func, errFunc);
    };
    Vue.prototype.$execWithLoadingMessage = async function (msg, func, errFunc) {
      const loading = this.$loading({
        lock: true,
        text: msg,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let promise = new Promise((r) => {
        r();
      });

      return new Promise((r) => {
        setTimeout(async () => {
          promise = promise.then(() => func.apply(this));
          try {
            await promise;
          } catch (e) {
            console.log(`loading exception: ${e}`);
            console.trace();
            if (errFunc !== undefined) {
              try {
                errFunc(e);
              } catch (e2) {
                console.log(e2);
              }
            }
          }
          loading.close();
          r();
        }, 10);
      });
    };
    Vue.prototype.$defaultLoading = function () {
      return this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    };
    Vue.prototype.$showSuccess = function (title) {
      this.$notify.success({
        title,
      });
    };
    Vue.prototype.$showError = function (title) {
      this.$notify.error({
        title,
      });
    };
    Vue.prototype.$showWarn = function (title, message) {
      this.$notify({
        title,
        message,
        type: 'warning',
      });
    };
    Vue.prototype.$showAxiosException = function (title, e) {
      if (e.response?.status === 401) {
        this.$showError('登入已失效，請重新登入');
        this.$router.push('/login');
        return;
      }
      const errKey = e.response?.data?.error || 'unknown';
      console.log({ title, errorMsgMap, errKey });
      setTimeout(() => {
        this.$showError(`${title}: ${errorMsgMap[errKey]}`);
      }, 10);
    };
    Vue.prototype.$waitMs = async function (ms) {
      return new Promise((r) => {
        setTimeout(() => {
          r();
        }, ms);
      });
    };
    Vue.prototype.$goLink = function (url) {
      if (url) {
        window.open(url, '_blank');
      }
    };

    Vue.prototype.$copy = function (text, notify) {
      const input = document.createElement('input');
      input.value = text;
      const body = document.querySelector('body');
      body.append(input);

      input.select();
      document.execCommand('copy');
      input.remove();

      if (notify) {
        this.$showSuccess(`已經成功複製 ${text}`);
      }
    };

    Vue.prototype.$showConfirm = function ({
      title, content, confirmButtonText, cancelButtonText, confirmType, customClass,
    }) {
      return new Promise((resolve, reject) => {
        this.$confirm(content, title, {
          confirmButtonText,
          cancelButtonText,
          type: confirmType,
          customClass,
        }).then(async () => {
          resolve();
        }).catch(() => {
          reject();
        });
      });
    };

    Vue.prototype.$downloadCSVFile = function (text, filename) {
      const blobData = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), text], { type: 'text/csv' });
      if (blobData != null && navigator.msSaveBlob) {
        navigator.msSaveBlob(blobData, filename);
        return;
      }
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blobData);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    };
  },
};
